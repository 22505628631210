import {
  Button,
  Card,
  CardItem,
  CardItemTitle,
  CardTitle,
  SimpleInput,
  Title,
} from '@partnerka-front/components';
import { Container, RefreshTokenModal } from '../components';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { SvgRefresh } from '@partnerka-front/icons';

const IntegrationItem = styled(CardItem)`
  grid-template-columns: 1fr 340px 350px;
`;

// TODO Сделать компонентом
const DescriptionPage = styled.p`
  max-width: 800px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 4px 16px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
  background-color: transparent;
  text-align: left;
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    color: ${({ theme }) => theme.colors.negative};
  }
`;

export function IntegrationPage() {
  const [token, setToken] = useState<string | null>(null);
  const [modal, setModal] = useState(false);

  const [sec, setSec] = useState(0);

  const restart = () => {
    setSec(59);
  };

  const tick = () => {
    setSec((prev) => prev - 1);
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  }, []);

  useEffect(() => {
    if (token && sec <= 0) {
      setToken(null);
    }
  }, [sec]);

  return (
    <Container>
      <Title marginBottom={40}>Настройка интеграции</Title>
      <DescriptionPage>
        Обратите внимание! Токен после создания доступен для копирования в течении 1 минуты.
        <br />
        При повторной попытке создания токена – будет создан новый ключ.
      </DescriptionPage>
      <Card>
        <CardTitle>API</CardTitle>
        <IntegrationItem>
          <CardItemTitle>Токен интеграции</CardItemTitle>
          {token ? (
            <SimpleInput currentValue={token} mode={'copy'} />
          ) : (
            <Text>Нажмите Refresh, чтобы получить токен</Text>
          )}
          <Right>
            <Button variant={'accent'} onClick={() => setModal(true)}>
              <SvgRefresh />
              Refresh
            </Button>
            {sec > 0 && (
              <p>
                Доступно для копирования – <span>{sec > 9 ? sec : `0${sec}`}</span>
              </p>
            )}
          </Right>
        </IntegrationItem>
      </Card>
      {modal && (
        <RefreshTokenModal
          setValue={setToken}
          onRestart={restart}
          onClose={() => setModal(false)}
        />
      )}
    </Container>
  );
}
