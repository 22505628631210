export function SvgCategory() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50562 5.1084C2.50562 3.72769 3.62491 2.6084 5.00562 2.6084H5.83895C7.21966 2.6084 8.33895 3.72769 8.33895 5.1084V5.94173C8.33895 7.32244 7.21966 8.44173 5.83895 8.44173H5.00562C3.62491 8.44173 2.50562 7.32244 2.50562 5.94173V5.1084Z"
        stroke="#35434E"
      />
      <path
        d="M11.6724 5.1084C11.6724 3.72769 12.7916 2.6084 14.1724 2.6084H15.0057C16.3864 2.6084 17.5057 3.72769 17.5057 5.1084V5.94173C17.5057 7.32244 16.3864 8.44173 15.0057 8.44173H14.1724C12.7916 8.44173 11.6724 7.32244 11.6724 5.94173V5.1084Z"
        stroke="#35434E"
      />
      <path
        d="M11.6724 14.2754C11.6724 12.8946 12.7916 11.7754 14.1724 11.7754H15.0057C16.3864 11.7754 17.5057 12.8946 17.5057 14.2754V15.1087C17.5057 16.4895 16.3864 17.6087 15.0057 17.6087H14.1724C12.7916 17.6087 11.6724 16.4895 11.6724 15.1087V14.2754Z"
        stroke="#35434E"
      />
      <path
        d="M2.50562 14.2754C2.50562 12.8946 3.62491 11.7754 5.00562 11.7754H5.83895C7.21966 11.7754 8.33895 12.8946 8.33895 14.2754V15.1087C8.33895 16.4895 7.21966 17.6087 5.83895 17.6087H5.00562C3.62491 17.6087 2.50562 16.4895 2.50562 15.1087V14.2754Z"
        stroke="#35434E"
      />
    </svg>
  );
}
