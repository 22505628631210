import {
  Button,
  Pagination,
  SkeletonTable,
  TableItem,
  TableNoData,
  TableRow,
  TableSorting,
  TableWrapper,
} from '@partnerka-front/components';
import { SvgNoDeals } from '@partnerka-front/icons';
import { DealsBusinessTableItem } from './item';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { useEffect, useState } from 'react';

export function DealsBusinessTable() {
  const { t } = useTranslation(['deals', 'errors']);
  const navigate = useNavigate();

  const {
    firstLoading,
    fetchItems,
    pending,
    items: deals,
    itemsTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    pagesTotal,
  } = usePaginated(
    (page, pageSize) => Api.getBusinessDeals(page, pageSize, { o: ordering }),
    `${t('errors.try_again_later', { ns: 'errors' })}`
  );

  const [ordering, setOrdering] = useState('-conversion_time');

  useEffect(() => {
    if (firstLoading) {
      page === 1 ? fetchItems() : setPage(1);
    }
  }, [ordering]);

  return (
    <>
      <TableWrapper width={'100%'}>
        <TableRow gridTemplateColumns={'100px 100px 90px 90px 115px 1fr 1fr 160px'}>
          <TableItem noRightBorder>ID Сделки</TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() => (ordering === '-cost' ? setOrdering('cost') : setOrdering('-cost'))}>
              Сумма
            </TableSorting>
          </TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() =>
                ordering === '-conversion_time'
                  ? setOrdering('conversion_time')
                  : setOrdering('-conversion_time')
              }>
              Создано
            </TableSorting>
          </TableItem>
          <TableItem noRightBorder>Партнёр</TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() =>
                ordering === '-status' ? setOrdering('status') : setOrdering('-status')
              }>
              Статус
            </TableSorting>
          </TableItem>
          <TableItem noRightBorder minWidth={140}>
            Каталог
          </TableItem>
          <TableItem noRightBorder minWidth={140}>
            Оффер
          </TableItem>
          <TableItem>Промокод</TableItem>
        </TableRow>

        {pending ? (
          <SkeletonTable
            gridTemplateColumns={'100px 100px 90px 90px 115px 1fr 1fr 160px'}
            width={'full'}
          />
        ) : (
          <>
            {deals.length > 0 ? (
              deals.map((deal) => <DealsBusinessTableItem key={deal.id} deal={deal} />)
            ) : (
              <TableNoData
                icon={<SvgNoDeals />}
                title={'У вас нет новых сделок'}
                // description={'Нажмите на кнопку и создайте свою первую сделку'}
                // btn={
                //   <Button variant={'accent'} onClick={() => navigate('/deals/create')}>
                //     Создать сделку
                //   </Button>
                // }
              />
            )}
          </>
        )}
      </TableWrapper>
      {deals && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
