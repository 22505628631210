import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Modal, ModalProps } from '@partnerka-front/components';
import { ResponseError, UnknownFunction } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';

interface RefreshTokenModalProps extends ModalProps {
  setValue: (value: string) => void;
  onRestart: UnknownFunction;
}

export function RefreshTokenModal({ setValue, onRestart, onClose }: RefreshTokenModalProps) {
  const { t } = useTranslation(['errors']);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const refresh = async () => {
    setPending(true);

    await Api.refreshBusinessToken()
      .then((res) => {
        setValue(res.data.token);
        onRestart();
        if (onClose) onClose();
      })
      .catch(setError);

    setPending(false);
  };

  return (
    <Modal
      onClose={onClose}
      pending={pending}
      leftBtn={
        <Button variant={'primary-inactive'} onClick={onClose}>
          Отменить
        </Button>
      }
      rightBtn={
        <Button variant={'accent'} onClick={refresh}>
          Создать
        </Button>
      }>
      <p>
        Обновление токена приведет к удалению текущего токена и созданию нового. Обратите внимание,
        что это может привести к потери интеграции, поэтому настоятельно рекомендуем обновлять токен
        только в случае необходимости.
        {/*Updating token will result in deletion of old token and the creation of a new one. Please note*/}
        {/*that this may cause integration to be disabled, so we strongly recommend updating token only*/}
        {/*if necessary.*/}
      </p>
      <p>
        Нажмите «Создать», чтобы продолжить.
        {/*Click «Create» to proceed.*/}
      </p>
      {error?.response.data && (
        <Message onClose={() => setError(null)}>
          {error.response.data.detail
            ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
            : t('errors.try_again_later', { ns: 'errors' })}
        </Message>
      )}
    </Modal>
  );
}
