import {
  Button,
  CardDropdown,
  CardHelp,
  CardItem,
  CardItemTitle,
  ControlledSimpleInput,
  Message,
  PageLoading,
} from '@partnerka-front/components';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { Api } from '@partnerka-front/api';
import { CatalogDto, CatalogEvents, ResponseError, UnknownFunction } from '@partnerka-front/types';
import { CatalogSettingLanding } from './catalog-setting-landing';
import { useState } from 'react';
import { toast } from 'react-toast';
import { useEventBus } from '@partnerka-front/utils';

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

interface CatalogSettingLandingsProps {
  catalog: CatalogDto;
}

export function CatalogSettingLandings({ catalog }: CatalogSettingLandingsProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const formSchema = Yup.object().shape({
    title: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    url: Yup.string()
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .url('Недопустимая ссылка'),
    image_url: Yup.string().url('Недопустимая ссылка'),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: '',
      url: '',
      image_url: '',
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.createLanding({
      ...data,
      catalog_id: catalog.id,
      image_url: data.image_url === '' ? undefined : data.image_url,
    })
      .then(() => {
        reset();
        emit(CatalogEvents.fetchSettingCatalog);
        toast.success('Посадочная страница создана!');
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}

      <CardDropdown title={'Посадочные страницы'}>
        {catalog.landings?.map((landing) => (
          <CatalogSettingLanding key={landing.id} landing={landing} />
        ))}

        <ItemMiddle>
          <CardItemTitle>Название страницы</CardItemTitle>
          <ControlledSimpleInput name={'title'} placeholder={'Название'} />
        </ItemMiddle>

        <ItemLong>
          <CardItemTitle>URL</CardItemTitle>
          <ControlledSimpleInput name={'url'} placeholder={'https://...'} />
        </ItemLong>

        <ItemMiddle>
          <CardItemTitle>Логотип</CardItemTitle>
          <ControlledSimpleInput name={'image_url'} placeholder={'Вставьте ссылку на файл'} />
          <CardHelp>
            Загрузите логотип с помощью любого файлообменника (Dropbox, Google disk) и вставьте
            скопированную ссылку
          </CardHelp>
        </ItemMiddle>

        <ItemMiddle>
          <Button maxWidth={'full'} variant={'basic'} onClick={submit} disabled={!isValid}>
            Добавить
          </Button>
        </ItemMiddle>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </CardDropdown>
    </FormProvider>
  );
}
