import {
  Pagination,
  SkeletonTable,
  TableItem,
  TableNoData,
  TableRow,
  TableWrapper,
} from '@partnerka-front/components';
import { PartnersBusinessTableItem } from './item';
import { usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { useTranslation } from 'react-i18next';
import { SvgFolderPlus2 } from '@partnerka-front/icons';

export function PartnersBusinessTable() {
  const { t } = useTranslation(['deals', 'errors']);

  const {
    pending,
    items: partners,
    itemsTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    pagesTotal,
  } = usePaginated(
    (page, pageSize) => Api.getPartners(page, pageSize),
    `${t('errors.try_again_later', { ns: 'errors' })}`
  );

  return (
    <>
      <TableWrapper width={'100%'}>
        <TableRow gridTemplateColumns={'1fr 1fr 1fr 1fr'}>
          <TableItem minWidth={200}>UID</TableItem>
          <TableItem minWidth={200}>USD Paid</TableItem>
          <TableItem minWidth={200}>USD Holding</TableItem>
          <TableItem minWidth={200}>Дата присоединения</TableItem>
        </TableRow>

        {pending ? (
          <SkeletonTable gridTemplateColumns={'1fr 1fr 1fr 1fr'} width={'full'} />
        ) : (
          <>
            {partners.length > 0 ? (
              partners.map((partner) => (
                <PartnersBusinessTableItem key={partner.uid} partner={partner} />
              ))
            ) : (
              <TableNoData
                icon={<SvgFolderPlus2 />}
                title={`${t('deals.no_data', { ns: 'deals' })}`}
              />
            )}
          </>
        )}
      </TableWrapper>
      {partners && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
