import {
  Title,
  TableDescription,
  TableItem,
  TableRow,
  TableWrapper,
  SkeletonTable,
  TableNoData,
  Pagination,
  Button,
  TableSorting,
} from '@partnerka-front/components';
import { FinanceBusinessTableItem } from './item';
import { usePaginated } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { useTranslation } from 'react-i18next';
import { SvgFolderPlus2 } from '@partnerka-front/icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function FinanceBusinessTable() {
  const { t } = useTranslation(['deals', 'errors']);
  const navigate = useNavigate();
  const {
    firstLoading,
    fetchItems,
    pending,
    items: deals,
    itemsTotal,
    page,
    setPage,
    pageSize,
    setPageSize,
    pagesTotal,
  } = usePaginated(
    (page, pageSize) => Api.getBusinessFinances(page, pageSize, { o: ordering }),
    `${t('errors.try_again_later', { ns: 'errors' })}`
  );

  const [ordering, setOrdering] = useState('-conversion_time');

  useEffect(() => {
    if (firstLoading) {
      page === 1 ? fetchItems() : setPage(1);
    }
  }, [ordering]);

  return (
    <>
      <Title>История расходов</Title>
      <TableDescription>
        В этой таблице отображаются все сделки, которые были отправлены с вашего back-end на сервера
        Myshare Systems. Обратите внимание, что обсчет даты одобрения сделки проходит автоматически.
      </TableDescription>
      <TableWrapper>
        <TableRow
          gridTemplateColumns={
            '100px 115px 90px 90px 200px 200px 160px 120px 120px 110px 110px 110px'
          }>
          <TableItem noRightBorder>ID Сделки</TableItem>
          <TableItem noRightBorder>Статус</TableItem>
          <TableItem noRightBorder>UID</TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() =>
                ordering === '-conversion_time'
                  ? setOrdering('conversion_time')
                  : setOrdering('-conversion_time')
              }>
              Создано
            </TableSorting>
          </TableItem>
          <TableItem noRightBorder>Каталог</TableItem>
          <TableItem noRightBorder>Оффер</TableItem>
          <TableItem noRightBorder>Промокод</TableItem>
          <TableItem noRightBorder>
            <TableSorting
              onClick={() => (ordering === '-cost' ? setOrdering('cost') : setOrdering('-cost'))}>
              Сумма чека
            </TableSorting>
          </TableItem>
          <TableItem noRightBorder>Начисления</TableItem>
          <TableItem noRightBorder>Страна</TableItem>
          <TableItem noRightBorder>OS</TableItem>
          <TableItem noRightBorder>Браузер</TableItem>
        </TableRow>
        {pending ? (
          <SkeletonTable
            gridTemplateColumns={
              '100px 115px 90px 90px 200px 200px 160px 120px 120px 110px 110px 110px'
            }
          />
        ) : (
          <>
            {deals.length > 0 ? (
              deals.map((deal) => <FinanceBusinessTableItem key={deal.id} deal={deal} />)
            ) : (
              <TableNoData
                icon={<SvgFolderPlus2 />}
                title={`${t('deals.no_data', { ns: 'deals' })}`}
                // description={'Нажмите на кнопку и создайте свою первую сделку'}
                // btn={
                //   <Button variant={'accent'} onClick={() => navigate('/deals/create')}>
                //     Создать сделку
                //   </Button>
                // }
              />
            )}
          </>
        )}
      </TableWrapper>
      {deals && (
        <Pagination
          current={page}
          setCurrent={setPage}
          itemsTotal={itemsTotal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pagesTotal={pagesTotal}
        />
      )}
    </>
  );
}
