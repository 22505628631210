export function SvgNoCatalogs() {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="16.5" y="8.5" width="39" height="57" rx="7.5" stroke="#2356F6" strokeWidth="3" />
      <path
        d="M12.9262 17.0529L6.92564 18.0069C3.65303 18.5272 1.33092 21.6148 1.73905 24.9033L5.85232 58.0457C6.26045 61.3342 9.24428 63.5782 12.5169 63.0579L14.1161 62.8037"
        stroke="#DBEAFE"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M59.0743 17.0529L65.0748 18.0069C68.3475 18.5272 70.6696 21.6148 70.2614 24.9033L66.1482 58.0457C65.74 61.3342 62.7562 63.5782 59.4836 63.0579L57.8843 62.8037"
        stroke="#DBEAFE"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <rect x="21" y="13" width="30" height="26" rx="6" fill="#DBEAFE" />
      <rect x="21" y="43" width="30" height="4" rx="2" fill="#DBEAFE" />
      <rect x="21" y="49" width="20" height="3" rx="1.5" fill="#DBEAFE" />
    </svg>
  );
}
