import { useState } from 'react';
import { toast } from 'react-toast';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  CardDropdown,
  CardItem,
  CardItemTitle,
  ControlledRadio,
  ControlledSelect,
  ControlledSimpleInput,
  Message,
  PageLoading,
  RadioOption,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CatalogDto, InfoDto, ResponseError } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

const Radios = styled.div`
  display: flex;
  gap: 64px;
`;

interface CatalogSettingAwardPromoProps {
  catalog: CatalogDto;
  tooltips?: InfoDto[] | null;
}

export function CatalogSettingAwardPromo({ catalog }: CatalogSettingAwardPromoProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const optionsRewardType = {
    null: '-',
    RevShare: 'RevShare',
    CPA: 'CPA',
    CPS: 'CPS',
    CPL: 'CPL',
    COD: 'COD',
    CPV: 'CPV',
    CPO: 'CPO',
    CPC: 'CPC',
    CPA_MIN_DEPOSIT: 'CPA – Baseline',
    CPA_BASELINE: 'CPA – Min deposit',
  };
  const optionsPromo: RadioOption[] = [
    { value: 'true', label: 'Разрешено' },
    { value: 'false', label: 'Запрещено' },
  ];
  const optionsModerationType = {
    FREE_ENTRY: 'Свободный вход',
    AUTO_MODERATION: 'Автоматическая модерация',
    MANUAL_MODERATION: 'Ручная модерация',
  };

  const formSchema = Yup.object().shape({
    min_promo_code_percent: Yup.number()
      .typeError(`${t('validation.required', { ns: 'validation' })}`)
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .min(2, 'Минимальный размер промокода - 2')
      .max(95, 'Максимальный размер промокода - 95')
      .test(
        'min_promo_code_percent',
        'Минимальный процент не может быть больше максимального',
        function (minPromoCodePercent?: number) {
          const { max_promo_code_percent } = this.parent as { max_promo_code_percent: number };
          return minPromoCodePercent === undefined || minPromoCodePercent <= max_promo_code_percent;
        }
      )
      .test(
        'min_max_promo_code_percent',
        'Максимальный процент не может быть меньше минимального',
        function (minPromoCodePercent?: number) {
          const { max_promo_code_percent } = this.parent as { max_promo_code_percent: number };
          return (
            minPromoCodePercent === undefined ||
            max_promo_code_percent === undefined ||
            max_promo_code_percent >= minPromoCodePercent
          );
        }
      ),
    max_promo_code_percent: Yup.number()
      .typeError(`${t('validation.required', { ns: 'validation' })}`)
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .min(2, 'Минимальный размер промокода - 2')
      .max(95, 'Максимальный размер промокода - 95')
      .test(
        'max_promo_code_percent',
        'Максимальный процент не может быть меньше минимального',
        function (maxPromoCodePercent?: number) {
          const { min_promo_code_percent } = this.parent as { min_promo_code_percent: number };
          return maxPromoCodePercent === undefined || maxPromoCodePercent >= min_promo_code_percent;
        }
      ),
    hold_duration: Yup.number()
      .typeError(`${t('validation.required', { ns: 'validation' })}`)
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .min(0, 'Минимальный период холда - 0 дней')
      .max(180, 'Максимальный период холда - 180 дней'),
    canceled_duration: Yup.number()
      .typeError(`${t('validation.required', { ns: 'validation' })}`)
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .min(1, 'Минимальное закрытие сделки - 1 день')
      .max(365, 'Максимальный закрытие сделки - 365 дней'),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      reward_type: catalog.reward_type ? catalog.reward_type : 'null',
      is_promocode_allowed: catalog.is_promocode_allowed ? 'true' : 'false',
      min_promo_code_percent: catalog.min_promo_code_percent,
      max_promo_code_percent: catalog.max_promo_code_percent,
      moderation_type: catalog.moderation_type,
      hold_duration: catalog.hold_duration.split(' ')[0],
      canceled_duration: catalog.canceled_duration.split(' ')[0],
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchCatalog(catalog.id, {
      reward_type: data.reward_type !== 'null' ? data.reward_type : null,
      is_promocode_allowed: data.is_promocode_allowed === 'true' ? true : false,
      min_promo_code_percent: data.min_promo_code_percent,
      max_promo_code_percent: data.max_promo_code_percent,
      moderation_type: data.moderation_type,
      hold_duration: `${data.hold_duration} 00:00:00`,
      canceled_duration: `${data.canceled_duration} 00:00:00`,
    })
      .then(() => {
        toast.success('Размер вознаграждения и промокоды изменены!');
      })
      .catch(setError);

    reset({}, { keepValues: true });
    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}
      <CardDropdown
        title={'Размер вознаграждения & Промокоды'}
        titleRightSection={
          isDirty && (
            <Button variant={'accent'} onClick={submit} disabled={!isValid}>
              Сохранить
            </Button>
          )
        }>
        <ItemMiddle>
          <CardItemTitle>
            Модель вознаграждения
            {/*<InfoTooltip name={'title'} tooltips={tooltips}>*/}
            {/*  <SvgInfo2 />*/}
            {/*</InfoTooltip>*/}
          </CardItemTitle>
          <ControlledSelect name={'reward_type'} options={optionsRewardType} />
        </ItemMiddle>

        <ItemLong>
          <CardItemTitle paddingTop={0}>
            Применение промокодов
            {/*<InfoTooltip name={'description'} tooltips={tooltips}>*/}
            {/*  <SvgInfo2 />*/}
            {/*</InfoTooltip>*/}
          </CardItemTitle>
          <Radios>
            <ControlledRadio name={'is_promocode_allowed'} options={optionsPromo} />
          </Radios>
        </ItemLong>

        <ItemMiddle>
          <CardItemTitle>Минимальный размер промокода</CardItemTitle>
          <ControlledSimpleInput name={'min_promo_code_percent'} type={'number'} />
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Максимальный размер промокода</CardItemTitle>
          <ControlledSimpleInput name={'max_promo_code_percent'} type={'number'} />
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Тип модерации</CardItemTitle>
          <ControlledSelect name={'moderation_type'} options={optionsModerationType} />
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Период холда</CardItemTitle>
          <ControlledSimpleInput name={'hold_duration'} type={'number'} />
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Закрытие сделки</CardItemTitle>
          <ControlledSimpleInput name={'canceled_duration'} type={'number'} />
        </ItemMiddle>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </CardDropdown>
    </FormProvider>
  );
}
