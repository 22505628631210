import { useState } from 'react';
import { BusinessStatisticDto, UserDto } from '@partnerka-front/types';
import { StatisticProvider, UserProvider } from '@partnerka-front/utils';
import { BusinessRoutes } from './business-routes';

export function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState<UserDto | undefined>(undefined);
  const [statistic, setStatistic] = useState<BusinessStatisticDto | undefined>(undefined);

  return (
    <UserProvider value={{ isAuth, setIsAuth, user, setUser }}>
      <StatisticProvider value={{ statistic, setStatistic }}>
        <BusinessRoutes />
      </StatisticProvider>
    </UserProvider>
  );
}
