import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { FullScreenSpinner, ModalRoot, PopperRoot } from '@partnerka-front/components';
import { ToastContainer } from 'react-toast';
import { Nav } from './nav';

const WrapperBusiness = styled.div`
  color: ${({ theme }) => theme.colors.headliner};

  .toastContainer {
    z-index: 1;
  }

  .toast.success {
    background-color: ${({ theme }) => theme.colors.positive};
  }

  .toast.error {
    background-color: ${({ theme }) => theme.colors.negative};
  }
`;

const ContainerApp = styled.div`
  display: flex;
  width: 100%;
  min-width: 1200px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 800px;
  margin: 0 20px;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 34px 0px;
`;

interface LayoutProps {
  loading: boolean;
}

export function LayoutBusiness({ loading }: LayoutProps) {
  if (loading) return <FullScreenSpinner />;

  return (
    <WrapperBusiness>
      <ContainerApp>
        <Nav />
        <Content>
          <Main>
            <Outlet />
          </Main>
        </Content>
      </ContainerApp>
      <ToastContainer delay={10000} />
      <ModalRoot />
      <PopperRoot />
    </WrapperBusiness>
  );
}
