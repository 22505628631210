import { CatalogDto } from '@partnerka-front/types';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import {
  CatalogConditions,
  CatalogDescription,
  CatalogInfo,
  CatalogLandings,
  CatalogMaterials,
  CatalogOffers,
  CatalogPreview,
  CatalogPromoPreview,
  CatalogRules,
  CatalogTrafficSources,
} from '@partnerka-front/components';

const CatalogWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  ${down('md')} {
    flex-direction: column;
    gap: 10px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
  min-width: 300px;

  ${down('md')} {
    width: 100%;
    max-width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

interface CatalogPreviewProps {
  catalog: CatalogDto;
}

export function CatalogPreviewBusiness({ catalog }: CatalogPreviewProps) {
  return (
    <CatalogWrapper>
      <Left>
        <CatalogPreview catalog={catalog} />
        <CatalogConditions catalog={catalog} />
        <CatalogInfo catalog={catalog} />
      </Left>
      <Right>
        <CatalogDescription catalog={catalog} />

        {catalog.is_confirmed && catalog.landings && catalog.landings.length > 0 && (
          <CatalogLandings
            idCatalog={catalog.id}
            landings={catalog.landings}
            domains={catalog.list_domains}
          />
        )}

        {catalog.is_promocode_allowed && <CatalogPromoPreview />}

        {catalog.offers.length > 0 && (
          <CatalogOffers hold={catalog.hold_duration} offers={catalog.offers} />
        )}

        {catalog.text_rule.length > 0 && (
          <CatalogRules id={catalog.catalog_id} rules={catalog.text_rule} />
        )}

        <CatalogTrafficSources rules={catalog.rules} />

        {catalog.promo_materials.length > 0 && (
          <CatalogMaterials materials={catalog.promo_materials} />
        )}
      </Right>
    </CatalogWrapper>
  );
}
