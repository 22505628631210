import { PartnersBusinessTable, Title } from '@partnerka-front/components';
import { Container } from '../components';

export function PartnersPage() {
  return (
    <Container>
      <Title marginBottom={40}>Мои партнёры</Title>
      <PartnersBusinessTable />
    </Container>
  );
}
