export function SvgWallet() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 5.94173C2.5 4.10078 3.99238 2.6084 5.83333 2.6084H14.1667C16.0076 2.6084 17.5 4.10078 17.5 5.94173V14.2751C17.5 16.116 16.0076 17.6084 14.1667 17.6084H5.83333C3.99238 17.6084 2.5 16.116 2.5 14.2751V5.94173Z"
        stroke="#35434E"
      />
      <path
        d="M10 10.1084C10 8.72765 11.1192 7.6084 12.5 7.6084H16.6667C17.1269 7.6084 17.5 7.9815 17.5 8.44173V11.7751C17.5 12.2353 17.1269 12.6084 16.6667 12.6084H12.5C11.1192 12.6084 10 11.4891 10 10.1084Z"
        stroke="#35434E"
      />
      <path d="M12.5 10.1084H12.5833" stroke="#35434E" strokeLinecap="round" />
    </svg>
  );
}
