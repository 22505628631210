import { useNavigate } from 'react-router-dom';
import { Button, DealsBusinessTable, Title, TitleSection } from '@partnerka-front/components';
import { Container } from '../components';

export function DealsPage() {
  const navigate = useNavigate();

  return (
    <Container>
      <TitleSection marginBottom={40}>
        <Title marginBottom={0}>Список сделок</Title>
        {/*<Button variant={'accent'} onClick={() => navigate('/deals/create')}>*/}
        {/*  Создать сделку*/}
        {/*</Button>*/}
      </TitleSection>
      <DealsBusinessTable />
    </Container>
  );
}
