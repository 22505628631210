import { PageLoading } from '@partnerka-front/components';
import { CardSettingCompany, Container } from '../components';
import { useEffect, useState } from 'react';
import { Api } from '@partnerka-front/api';
import { CompanyDto, ResponseError } from '@partnerka-front/types';

export function CompanySettingsPage() {
  const [company, setCompany] = useState<CompanyDto | null>(null);

  const getCompany = async () => {
    await Api.getCompany().then((res) => setCompany(res.data));
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (!company) return <PageLoading />;

  return (
    <Container>
      <CardSettingCompany company={company} />
    </Container>
  );
}
