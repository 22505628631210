import { useForm, FormProvider } from 'react-hook-form';
import { CatalogDto, ResponseError } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Button,
  CardDropdown,
  CardHelp,
  CardItem,
  CardItemTitle,
  ControlledRadio,
  ControlledSelect,
  ControlledSimpleInput,
  Message,
  PageLoading,
  RadioOption,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { toast } from 'react-toast';

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

const Radios = styled.div`
  display: flex;
  gap: 64px;
`;

interface CatalogSettingLocalizationProps {
  catalog: CatalogDto;
}

export function CatalogSettingLocalization({ catalog }: CatalogSettingLocalizationProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const optionsCookie = {
    null: '-',
    7: '7 дней',
    14: '14 дней',
    30: '30 дней',
    45: '45 дней',
    60: '60 дней',
    90: '90 дней',
    180: '180 дней',
  };
  const options: RadioOption[] = [
    { value: 'All', label: 'Все' },
    { value: 'Desktop', label: 'Desktop' },
    { value: 'Mobile', label: 'Mobile' },
  ];

  const handleArr = (arr: string[]) => {
    let result = '';

    for (let i = 0; i < arr.length; i++) {
      i === 0
        ? (result = result + arr[i].toUpperCase())
        : (result = `${result}, ${arr[i].toUpperCase()}`);
    }

    return result;
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      offer_geo: catalog.offer_geo ? handleArr(catalog.offer_geo) : '',
      localizations: catalog.localizations ? handleArr(catalog.localizations) : '',
      devices: catalog.devices,
      cookies_storage_days: catalog.cookies_storage_days ? catalog.cookies_storage_days : 'null',
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchCatalog(catalog.id, {
      offer_geo: data.offer_geo === '' ? null : data.offer_geo.replaceAll(' ', '').split(','),
      localizations:
        data.localizations === '' ? null : data.localizations.replaceAll(' ', '').split(','),
      cookies_storage_days: data.cookies_storage_days === 'null' ? null : data.cookies_storage_days,
      devices: data.devices,
    })
      .then(() => {
        toast.success('Локализация продукта изменена!');
      })
      .catch(setError);

    reset({}, { keepValues: true });
    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}

      <CardDropdown
        title={'Локализация продукта'}
        titleRightSection={
          isDirty && (
            <Button variant={'accent'} onClick={submit}>
              Сохранить
            </Button>
          )
        }>
        <ItemMiddle>
          <CardItemTitle>GEO таргетинг</CardItemTitle>
          <ControlledSimpleInput name={'offer_geo'} placeholder={'RU, ...'} />
          <CardHelp>
            Укажите 2ух-символьные коды стран через запятую по стандарту ISO 3166-1
          </CardHelp>
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Локализация продукта</CardItemTitle>
          <ControlledSimpleInput name={'localizations'} placeholder={'RU, ...'} />
          <CardHelp>
            Укажите 2ух-символьные коды языков через запятую по стандарту ISO 639-1
          </CardHelp>
        </ItemMiddle>

        <ItemLong>
          <CardItemTitle paddingTop={0}>Типы устройств</CardItemTitle>
          <Radios>
            <ControlledRadio name={'devices'} options={options} />
          </Radios>
        </ItemLong>

        <ItemMiddle>
          <CardItemTitle>Хранение cookie</CardItemTitle>
          <ControlledSelect name={'cookies_storage_days'} options={optionsCookie} />
        </ItemMiddle>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
            {error.response.data.detail === 'OFFER_GEO_IS_NOT_VALID' &&
              error.response.data.values &&
              `: ${handleArr(error.response.data.values)}.`}
            {error.response.data.detail === 'LOCALIZATION_IS_NOT_VALID' &&
              error.response.data.values &&
              `: ${handleArr(error.response.data.values)}.`}
          </Message>
        )}
      </CardDropdown>
    </FormProvider>
  );
}
