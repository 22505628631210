export function SvgUserRectangle() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1667 2.6084H5.83333C3.99238 2.6084 2.5 4.10078 2.5 5.94173V14.2751C2.5 16.116 3.99238 17.6084 5.83333 17.6084H14.1667C16.0076 17.6084 17.5 16.116 17.5 14.2751V5.94173C17.5 4.10078 16.0076 2.6084 14.1667 2.6084Z"
        stroke="#35434E"
      />
      <path
        d="M10 10.9414C11.3807 10.9414 12.5 9.82212 12.5 8.44141C12.5 7.06069 11.3807 5.94141 10 5.94141C8.61929 5.94141 7.5 7.06069 7.5 8.44141C7.5 9.82212 8.61929 10.9414 10 10.9414Z"
        stroke="#35434E"
      />
      <path
        d="M15.4505 17.1914C14.6117 14.9986 12.4878 13.4414 10.0001 13.4414C7.51245 13.4414 5.38849 14.9986 4.5498 17.1914"
        stroke="#35434E"
      />
    </svg>
  );
}
