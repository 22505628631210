export function SvgFolderPlus() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 5.1084C2.5 3.72769 3.61929 2.6084 5 2.6084H7.43464C7.87667 2.6084 8.30059 2.78399 8.61317 3.09656L9.51183 3.99524C9.82442 4.30781 10.2483 4.4834 10.6903 4.4834H15C16.3807 4.4834 17.5 5.60269 17.5 6.9834V15.1084C17.5 16.4891 16.3807 17.6084 15 17.6084H5C3.61929 17.6084 2.5 16.4891 2.5 15.1084V5.1084Z"
        stroke="#35434E"
      />
      <path d="M7.5 10.9419H12.5M10 8.44189V13.4419" stroke="#35434E" strokeLinecap="round" />
    </svg>
  );
}
