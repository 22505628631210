import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  Button,
  CardItem,
  CardItemTitle,
  ControlledRadio,
  ControlledSimpleInput,
  ControlledTextarea,
  ControlledToggle,
  Message,
  PageLoading,
  RadioOption,
  SimpleInput,
} from '@partnerka-front/components';
import { Currency, OfferDto, ResponseError } from '@partnerka-front/types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Api } from '@partnerka-front/api';
import { SvgArrows } from '@partnerka-front/icons';
import { toast } from 'react-toast';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    width: calc(100% - 24px);
    height: 1px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.inactiveGrey};
  }
`;

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

const Radios = styled.div`
  display: flex;
  gap: 64px;
`;

const SectionCount = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  & > *:last-child {
    width: 100%;
  }
`;

const Method = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
`;

const RightBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface DropdownProps {
  isShow: boolean;
}

const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 0;
  transform: ${({ isShow }) => (isShow ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: 0.3s;
  cursor: pointer;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.headliner};
    }
  }
`;

interface PublicProps {
  isActive: boolean;
}

const Public = styled.div<PublicProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.basic : theme.colors.inactiveGrey};
`;

const Moderation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 28px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.notification};
`;

interface SectionToggleProps {
  isActive: boolean;
}

const SectionToggle = styled.div<SectionToggleProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 16px;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.accent : theme.colors.support)};
    transition: 0.3s;
  }
`;

interface CatalogSettingTariffProps {
  offer: OfferDto;
}

export function CatalogSettingOffer({ offer }: CatalogSettingTariffProps) {
  const { t } = useTranslation(['errors', 'validation']);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);
  const [isShow, setIsShow] = useState(false);

  const options: RadioOption[] = [
    { value: 'fix_remuneration', label: 'Фиксированная' },
    { value: 'reward_percent', label: 'Процент от чека' },
  ];

  const priceUSD = offer.prices.find((item) => item.currency === Currency.USD);
  const costStart = priceUSD ? priceUSD.cost : '';

  const formSchema = Yup.object().shape({
    name: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    cost: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    fix_remuneration: Yup.string().when('reward_model', {
      is: (reward_model: string) => reward_model === 'fix_remuneration',
      then: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    }),
    reward_percent: Yup.string().when('reward_model', {
      is: (reward_model: string) => reward_model === 'reward_percent',
      then: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    }),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      is_active: offer.is_active,
      name: offer.name,
      description: offer.description ? offer.description : '',
      cost: costStart,
      reward_model: offer.fix_remuneration ? 'fix_remuneration' : 'reward_percent',
      fix_remuneration: offer.fix_remuneration ? offer.fix_remuneration : '',
      reward_percent: offer.reward_percent ? offer.reward_percent : '',
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    watch,
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchOffer(offer.id, {
      name: data.name,
      description: data.description,
      prices: [{ cost: Number(data.cost), currency: Currency.USD }],
      fix_remuneration:
        data.reward_model === 'fix_remuneration' ? Number(data.fix_remuneration) : null,
      reward_percent: data.reward_model === 'reward_percent' ? Number(data.reward_percent) : null,
      is_active: data.is_active,
    })
      .then(() => {
        toast.success('Оффер изменен!');

        reset({
          is_active: data.is_active,
          name: data.name,
          description: data.description,
          cost: data.cost,
          reward_model: data.reward_model,
          fix_remuneration: data.fix_remuneration,
          reward_percent: data.reward_percent,
        });
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <Wrapper>
      {pending && <PageLoading />}

      <ItemMiddle>
        <div>{offer.name}</div>
        {offer.was_moderated ? (
          <Public isActive={offer.is_active}>
            {offer.is_active ? 'Опубликован' : 'Не опубликован'}
          </Public>
        ) : (
          <Moderation>Ожидает модерации</Moderation>
        )}
        <Dropdown isShow={isShow} onClick={() => setIsShow(!isShow)}>
          <SvgArrows />
        </Dropdown>
      </ItemMiddle>

      {isShow && (
        <FormProvider {...formMethods}>
          <ItemMiddle>
            <CardItemTitle>Название оффера</CardItemTitle>
            <ControlledSimpleInput name={'name'} />
            {offer.was_moderated && (
              <SectionToggle isActive={watch('is_active')}>
                <span>{watch('is_active') ? 'Опубликован' : 'Не опубликован'}</span>
                <ControlledToggle name={'is_active'} />
              </SectionToggle>
            )}
          </ItemMiddle>

          <ItemLong>
            <CardItemTitle>Описание оффера</CardItemTitle>
            <ControlledTextarea name={'description'} max={256} />
          </ItemLong>

          <ItemMiddle>
            <CardItemTitle>ID</CardItemTitle>
            <SimpleInput currentValue={offer.id} disabled />
            <div></div>
          </ItemMiddle>

          <ItemMiddle>
            <CardItemTitle>Стоимость оффера</CardItemTitle>
            <SectionCount>
              <Method>USD</Method>
              <ControlledSimpleInput name={'cost'} placeholder={'00.00'} type={'number'} />
            </SectionCount>
          </ItemMiddle>

          <ItemLong>
            <CardItemTitle paddingTop={0}>Модель вознагражения</CardItemTitle>
            <Radios>
              <ControlledRadio name={'reward_model'} options={options} />
            </Radios>
          </ItemLong>

          <ItemMiddle>
            <div />
            {watch('reward_model') === 'fix_remuneration' && (
              <SectionCount>
                <Method>USD</Method>
                <ControlledSimpleInput
                  name={'fix_remuneration'}
                  placeholder={'00.00'}
                  type={'number'}
                />
              </SectionCount>
            )}
            {watch('reward_model') === 'reward_percent' && (
              <SectionCount>
                <Method>%</Method>
                <ControlledSimpleInput name={'reward_percent'} placeholder={'5'} type={'number'} />
              </SectionCount>
            )}
          </ItemMiddle>

          <RightBtn>
            <Button variant={'accent'} onClick={submit} disabled={!isDirty}>
              Сохранить изменения
            </Button>
          </RightBtn>
        </FormProvider>
      )}

      {error?.response.data && (
        <Message onClose={() => setError(null)}>
          {error.response.data.detail
            ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
            : t('errors.try_again_later', { ns: 'errors' })}
          {error.response.data.detail === 'PART_MORE_THEN_REMUNERATION_PERCENT' && (
            <>
              Для изменения обратитесь в{' '}
              <a href={'https://t.me/aff_good_boy'} target={'_blank'} rel="noreferrer">
                тех.поддержку
              </a>
            </>
          )}
          {error.response.data.detail === 'PART_MORE_THEN_FIX_REMUNERATION' && (
            <>
              Для изменения обратитесь в{' '}
              <a href={'https://t.me/aff_good_boy'} target={'_blank'} rel="noreferrer">
                тех.поддержку
              </a>
            </>
          )}
        </Message>
      )}
    </Wrapper>
  );
}
