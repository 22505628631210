import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { PageLoading } from '@partnerka-front/components';
import { AppThemeProvider } from '@partnerka-front/theme';
import { App } from './components';
import './i18n/config';

const root = ReactDOM.createRoot(document.getElementById('wrapper') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Suspense fallback={<PageLoading />}>
        <App />
      </Suspense>
    </AppThemeProvider>
  </React.StrictMode>
);
