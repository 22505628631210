export function SvgBarChart() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50012 5.83333C2.50012 3.99238 3.99251 2.5 5.83346 2.5H14.1668C16.0077 2.5 17.5001 3.99238 17.5001 5.83333V14.1667C17.5001 16.0076 16.0077 17.5 14.1668 17.5H5.83346C3.99251 17.5 2.50012 16.0076 2.50012 14.1667V5.83333Z"
        stroke="#35434E"
      />
      <path d="M6.66675 9.1665V13.3332" stroke="#35434E" strokeLinecap="round" />
      <path d="M13.3335 10.8335V13.3335" stroke="#35434E" strokeLinecap="round" />
      <path d="M10.0001 6.6665V13.3332" stroke="#35434E" strokeLinecap="round" />
    </svg>
  );
}
