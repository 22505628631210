import {
  Button,
  CardDropdown,
  CardHelp,
  CardItem,
  CardItemTitle,
  ControlledSelect,
  ControlledSimpleInput,
  ControlledTextarea,
  dateFormat,
  Message,
  PageLoading,
  SimpleInput,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { CatalogDto, ResponseError } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Api } from '@partnerka-front/api';
import { toast } from 'react-toast';

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

function Items() {
  const optionsCategories = {
    none: 'Выберите категорию',
    Education: 'Образование',
    Betting: 'Ставки на спорт',
    Software: 'Софт и ПО',
    Gambling: 'Казино и азартные игры',
  };

  return (
    <>
      <ItemMiddle>
        <CardItemTitle>Название каталога</CardItemTitle>
        <ControlledSimpleInput name={'title'} placeholder={'Название'} />
      </ItemMiddle>

      <ItemLong>
        <CardItemTitle>Описание</CardItemTitle>
        <ControlledTextarea name={'description'} placeholder={'Описание каталога'} max={500} />
      </ItemLong>

      <ItemLong>
        <CardItemTitle>Обложка каталога</CardItemTitle>
        <ControlledSimpleInput name={'preview_image'} placeholder={'https://'} />
      </ItemLong>

      <ItemLong>
        <CardItemTitle>Категория бизнеса</CardItemTitle>
        <ControlledSelect name={'category'} options={optionsCategories} />
      </ItemLong>
    </>
  );
}

interface CatalogSettingGeneralInformationCreateProps {
  error: ResponseError | null;
  setError: (value: ResponseError | null) => void;
}

export function CatalogSettingGeneralInformationCreate({
  error,
  setError,
}: CatalogSettingGeneralInformationCreateProps) {
  const { t } = useTranslation(['errors']);

  return (
    <CardDropdown title={'Общая информация'}>
      <Items />

      <ItemMiddle>
        <CardItemTitle>Код каталога для интеграции</CardItemTitle>
        <ControlledSimpleInput name={'catalog_code'} placeholder={'Text'} />
      </ItemMiddle>

      {error?.response.data && (
        <Message onClose={() => setError(null)}>
          {error.response.data.detail
            ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
            : t('errors.try_again_later', { ns: 'errors' })}
        </Message>
      )}
    </CardDropdown>
  );
}

interface CatalogSettingGeneralInformationUpdateProps {
  catalog: CatalogDto;
}

export function CatalogSettingGeneralInformationUpdate({
  catalog,
}: CatalogSettingGeneralInformationUpdateProps) {
  const { t } = useTranslation(['errors', 'validation']);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const formSchema = Yup.object().shape({
    title: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    description: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    preview_image: Yup.string().url('Недопустимая ссылка'),
    catalog_code: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    category: Yup.string().notOneOf(['none'], 'Выберите категорию'),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: catalog.title,
      description: catalog.description,
      preview_image: catalog.preview_image ? catalog.preview_image : '',
      catalog_code: catalog.catalog_code,
      category: catalog.category ? catalog.category : 'none',
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchCatalog(catalog.id, {
      ...data,
      preview_image: data.preview_image === '' ? undefined : data.preview_image,
    })
      .then(() => {
        toast.success('Общая информация изменена!');
      })
      .catch(setError);

    reset({}, { keepValues: true });
    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}
      <CardDropdown
        title={'Общая информация'}
        titleRightSection={
          isDirty && (
            <Button variant={'accent'} onClick={submit}>
              Сохранить
            </Button>
          )
        }>
        <Items />

        <ItemMiddle>
          <CardItemTitle>Код каталога для интеграции</CardItemTitle>
          <SimpleInput currentValue={catalog.catalog_code} disabled />
          <CardHelp>
            <p>
              Для изменения обратитесь в{' '}
              <a href={'https://t.me/aff_good_boy'} target={'_blank'} rel="noreferrer">
                тех.поддержку
              </a>
            </p>
          </CardHelp>
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Каталог ID</CardItemTitle>
          <SimpleInput currentValue={catalog.id} disabled />
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Short ID</CardItemTitle>
          <SimpleInput currentValue={catalog.catalog_id} disabled />
        </ItemMiddle>

        <ItemMiddle>
          <CardItemTitle>Дата создания</CardItemTitle>
          <SimpleInput currentValue={dateFormat(catalog.modified_at)} disabled />
        </ItemMiddle>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </CardDropdown>
    </FormProvider>
  );
}
