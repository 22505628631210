import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NoRequireAuth, RequireAuth } from '@partnerka-front/components';
import { useEffect, useState } from 'react';
import { useUser } from '@partnerka-front/utils';
import { LayoutBusiness } from './layout';
import {
  CatalogCreatePage,
  CatalogPreviewPage,
  CatalogSettingPage,
  CatalogsPage,
  CompanySettingsPage,
  CompanyStatisticsPage,
  DealsPage,
  FinancePage,
  IntegrationPage,
  PartnersPage,
} from '../pages';
import {
  LoginPage,
  NotFoundPage,
  PasswordResetConfirmPage,
  PasswordResetPage,
  RegistrationPage,
} from '@partnerka-front/pages';

export function BusinessRoutes() {
  const { isAuth, fetchUser } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    if (localStorage.getItem('refresh')) await fetchUser();

    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<LayoutBusiness loading={isLoading} />}>
          {
            //-------------------------------------------------------------------//
            //                                                                   //
            //                               Index                               //
            //                                                                   //
            //-------------------------------------------------------------------//
          }
          <Route index element={isAuth ? <CompanyStatisticsPage /> : <LoginPage isBusiness />} />
          {
            //-------------------------------------------------------------------//
            //                                                                   //
            //                               Auth                                //
            //                                                                   //
            //-------------------------------------------------------------------//
          }
          <Route
            path={'login'}
            element={
              <NoRequireAuth>
                <LoginPage isBusiness />
              </NoRequireAuth>
            }
          />
          <Route
            path={'registration'}
            element={
              <NoRequireAuth>
                <RegistrationPage isBusiness />
              </NoRequireAuth>
            }
          />
          <Route
            path={'/ref/:uid'}
            element={
              <NoRequireAuth>
                <RegistrationPage isBusiness />
              </NoRequireAuth>
            }
          />
          <Route
            path={'activate/:uid/:token'}
            element={
              <NoRequireAuth>
                <LoginPage isBusiness />
              </NoRequireAuth>
            }
          />
          <Route
            path={'password-reset'}
            element={
              <NoRequireAuth>
                <PasswordResetPage isBusiness />
              </NoRequireAuth>
            }
          />
          <Route
            path={'password-reset/confirm/:uid/:token'}
            element={
              <NoRequireAuth>
                <PasswordResetConfirmPage />
              </NoRequireAuth>
            }
          />
          <Route
            path={'password-reset/:status'}
            element={
              <NoRequireAuth>
                <LoginPage isBusiness />
              </NoRequireAuth>
            }
          />
          {
            //-------------------------------------------------------------------//
            //                                                                   //
            //                               Panel                               //
            //                                                                   //
            //-------------------------------------------------------------------//
          }
          <Route
            path={'finance'}
            element={
              <RequireAuth>
                <FinancePage />
              </RequireAuth>
            }
          />
          <Route
            path={'deals'}
            element={
              <RequireAuth>
                <DealsPage />
              </RequireAuth>
            }
          />
          <Route
            path={'partners'}
            element={
              <RequireAuth>
                <PartnersPage />
              </RequireAuth>
            }
          />
          <Route
            path={'settings'}
            element={
              <RequireAuth>
                <CompanySettingsPage />
              </RequireAuth>
            }
          />
          <Route
            path={'catalogs'}
            element={
              <RequireAuth>
                <CatalogsPage />
              </RequireAuth>
            }
          />
          <Route
            path={'catalogs/create'}
            element={
              <RequireAuth>
                <CatalogCreatePage />
              </RequireAuth>
            }
          />
          <Route
            path={'catalogs/:id'}
            element={
              <RequireAuth>
                <CatalogSettingPage />
              </RequireAuth>
            }
          />
          <Route
            path={'catalogs/preview/:id'}
            element={
              <RequireAuth>
                <CatalogPreviewPage />
              </RequireAuth>
            }
          />
          <Route
            path={'integration'}
            element={
              <RequireAuth>
                <IntegrationPage />
              </RequireAuth>
            }
          />
        </Route>
        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                                404                                //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
