import styled from 'styled-components';
import {
  CardBalance,
  CardFinancialExpenditure,
  CardHold,
  FinanceBusinessTable,
  Title,
} from '@partnerka-front/components';
import { Container } from '../components';
import { Api } from '@partnerka-front/api';
import { useEffect } from 'react';
import { useStatistic } from '@partnerka-front/utils';

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 50px;
`;

export function FinancePage() {
  const { statistic, setStatistic } = useStatistic();

  const getStatistic = async () => {
    await Api.getBusinessStatistic().then((res) => setStatistic(res.data));
  };

  useEffect(() => {
    getStatistic();
  }, []);

  return (
    <>
      <Container>
        <Title>Статистика компании</Title>
        <Cards>
          {statistic && (
            <>
              <CardBalance />
              <CardHold />
              <CardFinancialExpenditure />
            </>
          )}
        </Cards>
      </Container>
      <FinanceBusinessTable />
    </>
  );
}
