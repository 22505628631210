import {
  Button,
  CardContentDropdown,
  CardDropdowns,
  CardItem,
  CardItemTitle,
  ControlledRadio,
  Message,
  PageLoading,
  RadioOption,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { CatalogDto, ResponseError } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Api } from '@partnerka-front/api';
import { toast } from 'react-toast';

const Item = styled(CardItem)`
  grid-template-columns: 250px 1fr;
  margin-bottom: 36px;
  padding-left: 0;
  padding-right: 0;
`;

const Radios = styled.div`
  display: flex;
  gap: 64px;
`;

interface CatalogSettingTrafficProps {
  catalog: CatalogDto;
}

export function CatalogSettingTraffic({ catalog }: CatalogSettingTrafficProps) {
  const { t } = useTranslation(['errors', 'validation']);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const options: RadioOption[] = [
    { value: 'true', label: 'Разрешен' },
    { value: 'false', label: 'Запрещен' },
    { value: 'null', label: 'Не отображать' },
  ];

  const handleState = (value?: boolean) => {
    if (value === true) return 'true';
    if (value === false) return 'false';
    return 'null';
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      WEBSITE: handleState(catalog.rules.WEBSITE.is_permitted),
      ONION_SITE: handleState(catalog.rules.ONION_SITE.is_permitted),
      FORUM: handleState(catalog.rules.FORUM.is_permitted),
      WHITE_DOORWAYS: handleState(catalog.rules.WHITE_DOORWAYS.is_permitted),
      GRAY_DOORWAYS: handleState(catalog.rules.GRAY_DOORWAYS.is_permitted),
      BLACK_DOORWAYS: handleState(catalog.rules.BLACK_DOORWAYS.is_permitted),
      CASHBACK: handleState(catalog.rules.CASHBACK.is_permitted),
      NOTICE_BOARDS: handleState(catalog.rules.NOTICE_BOARDS.is_permitted),
      CONTEXTUAL_ADVERTISING: handleState(catalog.rules.CONTEXTUAL_ADVERTISING.is_permitted),
      CONTEXTUAL_ADVERTISING_ON_THE_BRAND: handleState(
        catalog.rules.CONTEXTUAL_ADVERTISING_ON_THE_BRAND.is_permitted
      ),
      BANNER_ADVERTISING: handleState(catalog.rules.BANNER_ADVERTISING.is_permitted),
      TARGETED_ADVERTISING_IN_SOCIAL_MEDIA: handleState(
        catalog.rules.TARGETED_ADVERTISING_IN_SOCIAL_MEDIA.is_permitted
      ),
      TELEGRAM_ADS: handleState(catalog.rules.TELEGRAM_ADS.is_permitted),
      TEASER_ADVERTISING: handleState(catalog.rules.TEASER_ADVERTISING.is_permitted),
      PUSH_NOTIFICATIONS: handleState(catalog.rules.PUSH_NOTIFICATIONS.is_permitted),
      CLICK_UNDER: handleState(catalog.rules.CLICK_UNDER.is_permitted),
      POP_UP: handleState(catalog.rules.POP_UP.is_permitted),
      POP_UNDER: handleState(catalog.rules.POP_UNDER.is_permitted),
      TOOLBAR: handleState(catalog.rules.TOOLBAR.is_permitted),
      PWA: handleState(catalog.rules.PWA.is_permitted),
      MOBILE_TRAFFIC: handleState(catalog.rules.MOBILE_TRAFFIC.is_permitted),
      ADULT_TRAFFIC: handleState(catalog.rules.ADULT_TRAFFIC.is_permitted),
      EMAIL: handleState(catalog.rules.EMAIL.is_permitted),
      SMS: handleState(catalog.rules.SMS.is_permitted),
      VIDEO_ADS: handleState(catalog.rules.VIDEO_ADS.is_permitted),
      NATIVE_ADVERTISING: handleState(catalog.rules.NATIVE_ADVERTISING.is_permitted),
      MOTIVATED_TRAFFIC: handleState(catalog.rules.MOTIVATED_TRAFFIC.is_permitted),
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = formMethods;

  const handleValue = (value: string) => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return undefined;
  };

  const submit = handleSubmit(async (data) => {
    setPending(true);

    await Api.patchSettingRuleTraffic(catalog.id, {
      WEBSITE: handleValue(data.WEBSITE),
      ONION_SITE: handleValue(data.ONION_SITE),
      FORUM: handleValue(data.FORUM),
      WHITE_DOORWAYS: handleValue(data.WHITE_DOORWAYS),
      GRAY_DOORWAYS: handleValue(data.GRAY_DOORWAYS),
      BLACK_DOORWAYS: handleValue(data.BLACK_DOORWAYS),
      CASHBACK: handleValue(data.CASHBACK),
      NOTICE_BOARDS: handleValue(data.NOTICE_BOARDS),
      CONTEXTUAL_ADVERTISING: handleValue(data.CONTEXTUAL_ADVERTISING),
      CONTEXTUAL_ADVERTISING_ON_THE_BRAND: handleValue(data.CONTEXTUAL_ADVERTISING_ON_THE_BRAND),
      BANNER_ADVERTISING: handleValue(data.BANNER_ADVERTISING),
      TARGETED_ADVERTISING_IN_SOCIAL_MEDIA: handleValue(data.TARGETED_ADVERTISING_IN_SOCIAL_MEDIA),
      TELEGRAM_ADS: handleValue(data.TELEGRAM_ADS),
      TEASER_ADVERTISING: handleValue(data.TEASER_ADVERTISING),
      PUSH_NOTIFICATIONS: handleValue(data.PUSH_NOTIFICATIONS),
      CLICK_UNDER: handleValue(data.CLICK_UNDER),
      POP_UP: handleValue(data.POP_UP),
      POP_UNDER: handleValue(data.POP_UNDER),
      TOOLBAR: handleValue(data.TOOLBAR),
      PWA: handleValue(data.PWA),
      MOBILE_TRAFFIC: handleValue(data.MOBILE_TRAFFIC),
      ADULT_TRAFFIC: handleValue(data.ADULT_TRAFFIC),
      EMAIL: handleValue(data.EMAIL),
      SMS: handleValue(data.SMS),
      VIDEO_ADS: handleValue(data.VIDEO_ADS),
      NATIVE_ADVERTISING: handleValue(data.NATIVE_ADVERTISING),
      MOTIVATED_TRAFFIC: handleValue(data.MOTIVATED_TRAFFIC),
    })
      .then(() => {
        toast.success('Настройки изменены!');
        reset({}, { keepValues: true });
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}
      <CardDropdowns
        title={'Вывод трафика'}
        titleRightSection={
          isDirty && (
            <Button variant={'accent'} onClick={submit}>
              Сохранить
            </Button>
          )
        }>
        <CardContentDropdown title={'Веб-ресурсы'}>
          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.WEBSITE.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'WEBSITE'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.ONION_SITE.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'ONION_SITE'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.FORUM.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'FORUM'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.WHITE_DOORWAYS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'WHITE_DOORWAYS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.GRAY_DOORWAYS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'GRAY_DOORWAYS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.BLACK_DOORWAYS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'BLACK_DOORWAYS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.CASHBACK.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'CASHBACK'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.NOTICE_BOARDS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'NOTICE_BOARDS'} options={options} />
            </Radios>
          </Item>
        </CardContentDropdown>

        <CardContentDropdown title={'Рекламные кабинеты'}>
          <Item>
            <CardItemTitle paddingTop={0}>
              {catalog.rules.CONTEXTUAL_ADVERTISING.label}
            </CardItemTitle>
            <Radios>
              <ControlledRadio name={'CONTEXTUAL_ADVERTISING'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>
              {catalog.rules.CONTEXTUAL_ADVERTISING_ON_THE_BRAND.label}
            </CardItemTitle>
            <Radios>
              <ControlledRadio name={'CONTEXTUAL_ADVERTISING_ON_THE_BRAND'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.BANNER_ADVERTISING.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'BANNER_ADVERTISING'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>
              {catalog.rules.TARGETED_ADVERTISING_IN_SOCIAL_MEDIA.label}
            </CardItemTitle>
            <Radios>
              <ControlledRadio name={'TARGETED_ADVERTISING_IN_SOCIAL_MEDIA'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.TELEGRAM_ADS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'TELEGRAM_ADS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.TEASER_ADVERTISING.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'TEASER_ADVERTISING'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.PUSH_NOTIFICATIONS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'PUSH_NOTIFICATIONS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.CLICK_UNDER.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'CLICK_UNDER'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.POP_UP.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'POP_UP'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.POP_UNDER.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'POP_UNDER'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.TOOLBAR.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'TOOLBAR'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.PWA.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'PWA'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.MOBILE_TRAFFIC.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'MOBILE_TRAFFIC'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.ADULT_TRAFFIC.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'ADULT_TRAFFIC'} options={options} />
            </Radios>
          </Item>
        </CardContentDropdown>

        <CardContentDropdown title={'Авторские ресурсы'}>
          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.EMAIL.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'EMAIL'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.SMS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'SMS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.VIDEO_ADS.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'VIDEO_ADS'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.NATIVE_ADVERTISING.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'NATIVE_ADVERTISING'} options={options} />
            </Radios>
          </Item>

          <Item>
            <CardItemTitle paddingTop={0}>{catalog.rules.MOTIVATED_TRAFFIC.label}</CardItemTitle>
            <Radios>
              <ControlledRadio name={'MOTIVATED_TRAFFIC'} options={options} />
            </Radios>
          </Item>
        </CardContentDropdown>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </CardDropdowns>
    </FormProvider>
  );
}
