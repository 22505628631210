export function SvgLogout() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.2666 12.1832L3.13327 10.0498L5.2666 7.9165"
        stroke="#868E95"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6665 10.0503H3.19151"
        stroke="#868E95"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 16.6668C13.6832 16.6668 16.6665 14.1668 16.6665 10.0002C16.6665 5.8335 13.6832 3.3335 9.99984 3.3335"
        stroke="#868E95"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
