import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TitleSection, Title, Button, Card, PageLoading } from '@partnerka-front/components';
import { CardCatalogBusiness, CardCatalogBusinessSkeleton, Container } from '../components';
import { BusinessCatalogDto } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';
import { SvgNoCatalogs } from '@partnerka-front/icons';

const ColumnsCatalogs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

const CardNoCatalogs = styled(Card)`
  justify-content: center;
  align-items: center;
  min-height: 500px;

  label {
    margin-top: 25px;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
  }

  p {
    max-width: 200px;
    margin-bottom: 25px;
    text-align: center;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export function CatalogsPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [catalogs, setCatalogs] = useState<BusinessCatalogDto[] | null>(null);

  const getCatalog = async () => {
    setLoading(true);
    await Api.getBusinessCatalogs().then((res) => setCatalogs(res.data));
    setLoading(false);
  };

  const updateCatalog = async () => {
    setPending(true);
    await Api.getBusinessCatalogs().then((res) => setCatalogs(res.data));
    setPending(false);
  };

  useEffect(() => {
    getCatalog();
  }, []);

  return (
    <Container>
      <TitleSection>
        <Title>Каталоги</Title>
        <Button variant={'accent'} onClick={() => navigate('/catalogs/create')}>
          Создать каталог
        </Button>
      </TitleSection>
      {pending && <PageLoading />}
      {!loading ? (
        <>
          {catalogs && catalogs?.length > 0 ? (
            <ColumnsCatalogs>
              {catalogs.map((catalog) => (
                <CardCatalogBusiness
                  key={catalog.id}
                  catalog={catalog}
                  updateCatalog={updateCatalog}
                />
              ))}
            </ColumnsCatalogs>
          ) : (
            <CardNoCatalogs>
              <SvgNoCatalogs />
              <label>Каталоги не найдены</label>
              <p>Нажмите на кнопку и создайте свой первый каталог</p>
              <Button variant={'accent'} onClick={() => navigate('/catalogs/create')}>
                Создать каталог
              </Button>
            </CardNoCatalogs>
          )}
        </>
      ) : (
        <ColumnsCatalogs>
          <CardCatalogBusinessSkeleton />
          <CardCatalogBusinessSkeleton />
          <CardCatalogBusinessSkeleton />
          <CardCatalogBusinessSkeleton />
          <CardCatalogBusinessSkeleton />
          <CardCatalogBusinessSkeleton />
        </ColumnsCatalogs>
      )}
    </Container>
  );
}
