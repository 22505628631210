import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Api } from '@partnerka-front/api';
import { useTranslation } from 'react-i18next';
import { CatalogEvents, Landing, ResponseError } from '@partnerka-front/types';
import styled from 'styled-components';
import {
  Button,
  CardHelp,
  CardItem,
  CardItemTitle,
  ControlledSimpleInput,
  Message,
  PageLoading,
  SimpleInput,
} from '@partnerka-front/components';
import { useState } from 'react';
import { toast } from 'react-toast';
import { useEventBus } from '@partnerka-front/utils';

const CatalogSettingLandingWrapper = styled.div`
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inactiveGrey};
`;

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

const RightBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface CatalogSettingLandingProps {
  landing: Landing;
}

export function CatalogSettingLanding({ landing }: CatalogSettingLandingProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const formSchema = Yup.object().shape({
    title: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    url: Yup.string()
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .url('Недопустимая ссылка'),
    image_url: Yup.string().url('Недопустимая ссылка'),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: landing.title,
      url: landing.url,
      image_url: landing.image_url ? landing.image_url : '',
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchLanding(landing.id, {
      ...data,
      image_url: data.image_url === '' ? undefined : data.image_url,
    })
      .then(() => {
        toast.success('Посадочная страница изменена!');
        reset({}, { keepValues: true });
      })
      .catch(setError);

    setPending(false);
  });

  const deleteLanding = async () => {
    setPending(true);
    setError(null);

    await Api.deleteLanding(landing.id)
      .then(() => {
        toast.success('Посадочная страница удалена!');
        setPending(false);
        emit(CatalogEvents.fetchSettingCatalog);
      })
      .catch(setError);

    setPending(false);
  };

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}

      <CatalogSettingLandingWrapper>
        <ItemMiddle>
          <CardItemTitle>Название страницы</CardItemTitle>
          <ControlledSimpleInput name={'title'} placeholder={'Название'} />
        </ItemMiddle>

        <ItemLong>
          <CardItemTitle>URL</CardItemTitle>
          <ControlledSimpleInput name={'url'} placeholder={'https://...'} />
        </ItemLong>

        <ItemLong>
          <CardItemTitle>ID</CardItemTitle>
          <SimpleInput currentValue={landing.id} disabled />
        </ItemLong>

        <ItemMiddle>
          <CardItemTitle>Логотип</CardItemTitle>
          <ControlledSimpleInput name={'image_url'} placeholder={'Вставьте ссылку на файл'} />
          <CardHelp>
            Загрузите логотип с помощью любого файлообменника (Dropbox, Google disk) и вставьте
            скопированную ссылку
          </CardHelp>
        </ItemMiddle>

        <RightBtn>
          {isDirty ? (
            <Button variant={'accent'} onClick={submit} disabled={!isValid}>
              Сохранить
            </Button>
          ) : (
            <Button variant={'inactiveGrey'} onClick={deleteLanding}>
              Удалить
            </Button>
          )}
        </RightBtn>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </CatalogSettingLandingWrapper>
    </FormProvider>
  );
}
