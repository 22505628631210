import { DateFormat, Price, TableItem, TableRow } from '@partnerka-front/components';
import { BusinessDealDto } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';

interface DealsBusinessTableItemProps {
  deal: BusinessDealDto;
}

export function DealsBusinessTableItem({ deal }: DealsBusinessTableItemProps) {
  const { t } = useTranslation(['deals']);

  return (
    <TableRow gridTemplateColumns={'100px 100px 90px 90px 115px 1fr 1fr 160px'}>
      <TableItem noRightBorder>{deal.id}</TableItem>
      <TableItem noRightBorder>
        <Price amount={deal.cost} currency={deal.currency} />
      </TableItem>
      <TableItem noRightBorder>
        <DateFormat date={deal.conversion_time} />
      </TableItem>
      <TableItem noRightBorder>{deal.uid}</TableItem>
      <TableItem noRightBorder>
        {deal.status === 'Approved' && t('deals.approved', { ns: 'deals' })}
        {deal.status === 'Paid' && t('deals.paid', { ns: 'deals' })}
        {deal.status === 'Created' && t('deals.created', { ns: 'deals' })}
        {deal.status === 'Canceled' && t('deals.canceled', { ns: 'deals' })}
        {deal.status === 'Rejected' && t('deals.rejected', { ns: 'deals' })}
      </TableItem>
      <TableItem noRightBorder minWidth={140}>
        {deal.offer.catalog.title}
      </TableItem>
      <TableItem noRightBorder minWidth={140}>
        {deal.offer.name}
      </TableItem>
      <TableItem>{deal.promo}</TableItem>
    </TableRow>
  );
}
