import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Button,
  CardItem,
  ControlledSelect,
  ControlledTextarea,
  Message,
  PageLoading,
} from '@partnerka-front/components';
import { CatalogEvents, GlobalEvents, ResponseError, TextRule } from '@partnerka-front/types';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Api } from '@partnerka-front/api';
import { useEventBus } from '@partnerka-front/utils';
import { toast } from 'react-toast';

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 24px;
`;

const ItemError = styled(CardItem)`
  grid-template-columns: 1fr;
`;

const RightBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
  padding-right: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface CatalogSettingRuleProps {
  rule: TextRule;
}

export function CatalogSettingRule({ rule }: CatalogSettingRuleProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const optionsLanguage = {
    EN: 'English',
    RU: 'Russian',
  };

  const formSchema = Yup.object().shape({
    language: Yup.string().test(
      'language-test',
      'Language can not be "none" string',
      function (value) {
        return value !== 'none';
      }
    ),
    text: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      language: rule.language,
      text: rule.text,
    },
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchTextRule(rule.id, data)
      .then(() => {
        emit(CatalogEvents.fetchSettingCatalog);

        const { language, text } = data;
        reset({ language, text });
      })
      .catch(setError);

    setPending(false);
  });

  const deleteRule = async () => {
    setPending(true);
    setError(null);

    await Api.deleteTextRule(rule.id)
      .then(() => {
        emit(GlobalEvents.fetchSettingCatalog);
        toast.success('Правило удалено!');
      })
      .catch(setError);

    setPending(false);
  };

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}

      <ItemLong>
        <ControlledSelect name={'language'} options={optionsLanguage} />
        <ControlledTextarea name={'text'} placeholder={'Вставьте html - код'} height={100} />
      </ItemLong>

      <RightBtn>
        <Button variant={'inactiveGrey'} onClick={deleteRule}>
          Удалить
        </Button>
        {isDirty && (
          <Button variant={'accent'} disabled={!isValid} onClick={submit}>
            Сохранить
          </Button>
        )}
      </RightBtn>

      {error?.response.data && (
        <ItemError>
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        </ItemError>
      )}
    </FormProvider>
  );
}
