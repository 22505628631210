import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Back, Button, PageLoading, Title, TitleSection } from '@partnerka-front/components';
import { CatalogSettingGeneralInformationCreate, Container } from '../components';
import { Api } from '@partnerka-front/api';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { ResponseError } from '@partnerka-front/types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toast';

const DescriptionPage = styled.p`
  max-width: 800px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Cards = styled.div`
  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export function CatalogCreatePage() {
  const { t } = useTranslation(['validation']);
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const formSchema = Yup.object().shape({
    title: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    description: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    preview_image: Yup.string().url('Недопустимая ссылка'),
    catalog_code: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    category: Yup.string().notOneOf(['none'], 'Выберите категорию'),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      catalog_code: '',
      preview_image: '',
      category: 'none',
    },
  });
  const { handleSubmit } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.createCatalog({
      ...data,
      preview_image: data.preview_image === '' ? undefined : data.preview_image,
    })
      .then((res) => {
        toast.success('Каталог создан');
        navigate(`/catalogs/${res.data.id}`);
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      <Back to={'/catalogs'} />
      <Container>
        {pending && <PageLoading />}
        <TitleSection marginBottom={16}>
          <Title marginBottom={0}>Новый каталог</Title>
          <Button variant={'accent'} onClick={() => submit()}>
            Создать
          </Button>
        </TitleSection>
        <DescriptionPage>
          Любое изменения данных сделки логируется в системе. Это необходимо для защиты владельцев
          бизнеса, веб-мастеров и арбитражников в случае возникновления спортных моментов.
        </DescriptionPage>
        <Cards>
          <CatalogSettingGeneralInformationCreate error={error} setError={setError} />
        </Cards>
      </Container>
    </FormProvider>
  );
}
