import { useState } from 'react';
import { toast } from 'react-toast';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  ModalLoading,
  Preview,
  PreviewPartner,
  PreviewPartnerSkeleton,
  PreviewSkeleton,
  SkeletonStyle,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { SvgEditNew } from '@partnerka-front/icons';
import { useNavigate } from 'react-router-dom';
import { BusinessCatalogDto, UnknownFunction } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';

const CatalogTitle = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 40px;
  margin: 24px 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
`;

const SectionButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const BtnEdit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.inactiveGrey};
  cursor: pointer;
`;

interface CardCatalogBusinessProps {
  catalog: BusinessCatalogDto;
  updateCatalog: UnknownFunction;
}

export function CardCatalogBusiness({ catalog, updateCatalog }: CardCatalogBusinessProps) {
  const { t } = useTranslation(['errors']);
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);

  const handlePublic = async () => {
    setPending(true);

    await Api.patchCatalog(catalog.id, { is_published: !catalog.is_published })
      .then(() => updateCatalog())
      .catch((error) => {
        const errorText = error.response.data.detail
          ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
          : t('errors.try_again_later', { ns: 'errors' });
        toast.error(errorText);
      });

    setPending(false);
  };

  return (
    <Card>
      {pending && <ModalLoading />}
      <div>
        <PreviewPartner name={catalog.company_name} logo={catalog.company_logo} />
        <Preview src={catalog.preview_image} />
        <CatalogTitle>{catalog.title}</CatalogTitle>
      </div>
      <SectionButtons>
        <BtnEdit onClick={() => navigate(catalog.id)}>
          <SvgEditNew />
        </BtnEdit>
        {catalog.is_published ? (
          <Button variant={'support'} maxWidth={'full'} onClick={handlePublic}>
            Снять с публикации
          </Button>
        ) : (
          <Button variant={'accent'} maxWidth={'full'} onClick={handlePublic}>
            Опубликовать
          </Button>
        )}
      </SectionButtons>
    </Card>
  );
}

const TitleSkeleton = styled.div`
  width: 100%;
  height: 40px;
  margin: 24px 0;

  ${SkeletonStyle};
`;

const BtnEditSkeleton = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;

  ${SkeletonStyle};
`;

const BtnSkeleton = styled.div`
  width: 100%;
  height: 40px;

  ${SkeletonStyle};
`;

export function CardCatalogBusinessSkeleton() {
  return (
    <Card>
      <div>
        <PreviewPartnerSkeleton />
        <PreviewSkeleton />
        <TitleSkeleton />
      </div>
      <SectionButtons>
        <BtnEditSkeleton />
        <BtnSkeleton />
      </SectionButtons>
    </Card>
  );
}
