import { FormProvider, useForm } from 'react-hook-form';
import { CatalogDto, CatalogEvents, Currency, ResponseError } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  CardDropdown,
  CardItem,
  CardItemTitle,
  ControlledRadio,
  ControlledSimpleInput,
  ControlledTextarea,
  Message,
  PageLoading,
  RadioOption,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { CatalogSettingOffer } from './catalog-setting-offer';
import { toast } from 'react-toast';
import { useEventBus } from '@partnerka-front/utils';

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

const Radios = styled.div`
  display: flex;
  gap: 64px;
`;

const RightBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  padding: 0 12px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionCount = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  & > *:last-child {
    width: 100%;
  }
`;

const Method = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
`;

interface CatalogSettingTariffsProps {
  catalog: CatalogDto;
}

export function CatalogSettingOffers({ catalog }: CatalogSettingTariffsProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [isNewOffer, setIsNewOffer] = useState(catalog.offers.length > 0 ? false : true);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const options: RadioOption[] = [
    { value: 'fix_remuneration', label: 'Фиксированная' },
    { value: 'reward_percent', label: 'Процент от чека' },
  ];

  const formSchema = Yup.object().shape({
    name: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    cost: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    fix_remuneration: Yup.string().when('reward_model', {
      is: (reward_model: string) => reward_model === 'fix_remuneration',
      then: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    }),
    reward_percent: Yup.string().when('reward_model', {
      is: (reward_model: string) => reward_model === 'reward_percent',
      then: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    }),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      cost: '',
      reward_model: 'fix_remuneration',
      fix_remuneration: '',
      reward_percent: '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.createOffer({
      catalog_id: catalog.id,
      name: data.name,
      prices: [{ cost: Number(data.cost), currency: Currency.USD }],
      fix_remuneration:
        data.reward_model === 'fix_remuneration' ? Number(data.fix_remuneration) : undefined,
      reward_percent:
        data.reward_model === 'reward_percent' ? Number(data.reward_percent) : undefined,
      is_active: true,
      description: data.description,
    })
      .then(() => {
        reset();
        setIsNewOffer(false);
        emit(CatalogEvents.fetchSettingCatalog);
        toast.success('Оффер создан!');
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <>
      <CardDropdown title={'Список офферов / тарифов'}>
        {pending && <PageLoading />}

        {catalog.offers.map((offer) => (
          <CatalogSettingOffer key={offer.id} offer={offer} />
        ))}

        {isNewOffer ? (
          <FormProvider {...formMethods}>
            <ItemMiddle>
              <CardItemTitle>Название оффера</CardItemTitle>
              <ControlledSimpleInput name={'name'} placeholder={'Название'} />
            </ItemMiddle>

            <ItemLong>
              <CardItemTitle>Описание оффера</CardItemTitle>
              <ControlledTextarea name={'description'} max={256} />
            </ItemLong>

            <ItemMiddle>
              <CardItemTitle>Стоимость оффера</CardItemTitle>
              <SectionCount>
                <Method>USD</Method>
                <ControlledSimpleInput name={'cost'} placeholder={'00.00'} type={'number'} />
              </SectionCount>
            </ItemMiddle>

            <ItemLong>
              <CardItemTitle paddingTop={0}>Модель вознагражения</CardItemTitle>
              <Radios>
                <ControlledRadio name={'reward_model'} options={options} />
              </Radios>
            </ItemLong>

            <ItemMiddle>
              <div />
              {watch('reward_model') === 'fix_remuneration' && (
                <SectionCount>
                  <Method>USD</Method>
                  <ControlledSimpleInput
                    name={'fix_remuneration'}
                    placeholder={'00.00'}
                    type={'number'}
                  />
                </SectionCount>
              )}
              {watch('reward_model') === 'reward_percent' && (
                <SectionCount>
                  <Method>%</Method>
                  <ControlledSimpleInput
                    name={'reward_percent'}
                    placeholder={'5'}
                    type={'number'}
                  />
                </SectionCount>
              )}
            </ItemMiddle>

            <RightBtn>
              <Button variant={'accent'} onClick={submit} disabled={!isValid}>
                Создать
              </Button>
            </RightBtn>

            {error?.response.data && (
              <Message onClose={() => setError(null)}>
                {error.response.data.detail
                  ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                  : t('errors.try_again_later', { ns: 'errors' })}
              </Message>
            )}
          </FormProvider>
        ) : (
          <ItemMiddle onClick={() => setIsNewOffer(true)}>
            <Button maxWidth={'full'} variant={'basic'}>
              Создать новый
            </Button>
          </ItemMiddle>
        )}
      </CardDropdown>
    </>
  );
}
