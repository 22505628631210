import { CatalogPreviewBusiness, Container } from '../components';
import { Back, PageLoading, Title } from '@partnerka-front/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CatalogDto } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';

export function CatalogPreviewPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [catalog, setCatalog] = useState<CatalogDto | null>(null);

  const getCatalog = async () => {
    if (id)
      await Api.getCatalog(id)
        .then((res) => setCatalog(res.data))
        .catch(() => navigate('/catalogs'));
  };

  useEffect(() => {
    getCatalog();
  }, []);

  if (!catalog) return <PageLoading />;

  return (
    <Container>
      <Back />
      <CatalogPreviewBusiness catalog={catalog} />
    </Container>
  );
}
