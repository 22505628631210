import { useForm, FormProvider } from 'react-hook-form';
import {
  CatalogEvents,
  PromoMaterialDto,
  ResponseError,
  TypeMaterial,
} from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  CardItem,
  ControlledSelect,
  ControlledSimpleInput,
  Message,
  PageLoading,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { useEventBus } from '@partnerka-front/utils';
import { toast } from 'react-toast';

const ItemLong = styled(CardItem)`
  grid-template-columns: 2fr 1fr;
  gap: 5px;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

const SectionSelect = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
`;

const SectionError = styled.div`
  margin-bottom: 56px;
  padding: 0 12px;
`;

interface CatalogSettingPromoMaterialProps {
  promoMaterial: PromoMaterialDto;
}

export function CatalogSettingPromoMaterial({ promoMaterial }: CatalogSettingPromoMaterialProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const options = {
    URL: 'URL',
    IMAGE: 'Изображение',
    FILE: 'Документ',
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    url: Yup.string()
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .url(`${t('validation.invalid_url', { ns: 'validation' })}`),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: promoMaterial.title,
      type_material: promoMaterial.type_material,
      url: promoMaterial.url,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = formMethods;

  const handleTypeMaterial = (str: string) => {
    if (str === TypeMaterial.FILE) return TypeMaterial.FILE;
    if (str === TypeMaterial.IMAGE) return TypeMaterial.IMAGE;
    return TypeMaterial.URL;
  };

  const submit = handleSubmit(async (data) => {
    setPending(true);

    await Api.patchPromoMaterial(promoMaterial.id, {
      title: data.title,
      type_material: handleTypeMaterial(data.type_material),
      url: data.url,
    })
      .then((res) => {
        toast.success('Промоматериал изменен!');
        const { title, type_material, url } = res.data;
        reset({ title, type_material, url });
      })
      .catch(setError);

    setPending(false);
  });

  const deletePromoMaterial = async () => {
    setPending(true);

    await Api.deletePromoMaterial(promoMaterial.id)
      .then(() => {
        toast.success('Промоматериал удален!');
        emit(CatalogEvents.fetchSettingCatalog);
      })
      .catch(setError);

    setPending(false);
  };

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}

      <ItemLong>
        <ControlledSimpleInput name={'title'} placeholder={'Заголовок'} />
        <Right>
          <Button variant={'inactiveGrey'} onClick={deletePromoMaterial}>
            Удалить
          </Button>
          <Button variant={'accent'} onClick={submit} disabled={!isValid || !isDirty}>
            Сохранить
          </Button>
        </Right>
      </ItemLong>

      <ItemLong>
        <SectionSelect>
          <ControlledSelect name={'type_material'} options={options} />
          <ControlledSimpleInput name={'url'} placeholder={`Вставьте ссылку`} />
        </SectionSelect>
      </ItemLong>

      <SectionError>
        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </SectionError>
    </FormProvider>
  );
}
