import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Card,
  CardHelp,
  CardItem,
  CardItemTitle,
  CardTitle,
  ControlledSimpleInput,
  Message,
  PageLoading,
  Title,
  TitleSection,
} from '@partnerka-front/components';
import { CompanyDto, ResponseError } from '@partnerka-front/types';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { toast } from 'react-toast';
import { regExpUrl } from '@partnerka-front/utils';

interface CardSettingCompanyProps {
  company: CompanyDto;
}

const Item = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

export function CardSettingCompany({ company }: CardSettingCompanyProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const [error, setError] = useState<ResponseError | null>(null);
  const [pending, setPending] = useState(false);

  const formSchema = Yup.object().shape({
    displayed_company: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    company_logo: Yup.string().url('Недопустимая ссылка'),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      displayed_company: company.displayed_company ? company.displayed_company : '',
      company_logo: company.company_logo ? company.company_logo : '',
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.patchCompany({
      ...data,
      company_logo: data.company_logo === '' ? undefined : data.company_logo,
    })
      .then(() => {
        toast('Настройки сохранены', {
          backgroundColor: '#89C541',
          color: '#ffffff',
        });
      })
      .catch(setError);

    reset({}, { keepValues: true });
    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}
      <TitleSection marginBottom={40}>
        <Title marginBottom={0}>Настройки компании</Title>
        {isDirty && (
          <Button variant={'accent'} onClick={submit}>
            Сохранить изменения
          </Button>
        )}
      </TitleSection>
      <Card>
        <CardTitle>Настройка компании</CardTitle>
        <Item>
          <CardItemTitle>Название компании</CardItemTitle>
          <ControlledSimpleInput name={'displayed_company'} placeholder={'Имя компании'} />
        </Item>

        <Item>
          <CardItemTitle>Логотип</CardItemTitle>
          <ControlledSimpleInput name={'company_logo'} placeholder={'Вставьте ссылку на файл'} />
          <CardHelp>
            Загрузите логотип с помощью любого файлообменника (Dropbox, Google disk) и вставьте
            скопированную ссылку
          </CardHelp>
        </Item>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </Card>
    </FormProvider>
  );
}
