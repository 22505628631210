import { useForm, FormProvider } from 'react-hook-form';
import { CatalogDto, GlobalEvents, ResponseError } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  CardDropdown,
  CardItem,
  ControlledSelect,
  ControlledTextarea,
  Message,
  PageLoading,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { CatalogSettingRule } from './catalog-setting-rule';
import { useEventBus } from '@partnerka-front/utils';
import { toast } from 'react-toast';

const ItemLong = styled(CardItem)`
  grid-template-columns: 1fr 2fr;
  gap: 24px;
`;

interface CatalogSettingRulesProps {
  catalog: CatalogDto;
}

export function CatalogSettingRules({ catalog }: CatalogSettingRulesProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const optionsLanguage = {
    none: 'Выбрать язык',
    EN: 'English',
    RU: 'Russian',
  };

  const formSchema = Yup.object().shape({
    language: Yup.string().test(
      'language-test',
      'Language can not be "none" string',
      function (value) {
        return value !== 'none';
      }
    ),
    text: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      language: 'none',
      text: '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = formMethods;

  const submit = handleSubmit(async (data) => {
    setPending(true);
    setError(null);

    await Api.createTextRule({
      catalog: catalog.id,
      text: data.text,
      language: data.language.toUpperCase(),
    })
      .then(() => {
        reset();
        emit(GlobalEvents.fetchSettingCatalog);
        toast.success('Правило создано!');
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      <CardDropdown title={'Правила оффера'}>
        {pending && <PageLoading />}

        {catalog.text_rule.map((rule) => (
          <CatalogSettingRule key={rule.id} rule={rule} />
        ))}

        {catalog.text_rule.length < 2 && (
          <>
            <ItemLong>
              <ControlledSelect name={'language'} options={optionsLanguage} />
              <ControlledTextarea name={'text'} placeholder={'Вставьте html - код'} height={100} />
            </ItemLong>

            <ItemLong>
              <Button maxWidth={'full'} variant={'basic'} disabled={!isValid} onClick={submit}>
                Добавить
              </Button>
            </ItemLong>

            {error?.response.data && (
              <Message onClose={() => setError(null)}>
                {error.response.data.detail
                  ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
                  : t('errors.try_again_later', { ns: 'errors' })}
              </Message>
            )}
          </>
        )}
      </CardDropdown>
    </FormProvider>
  );
}
