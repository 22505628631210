export function SvgSetting2() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0056 12.6074C11.3864 12.6074 12.5056 11.4882 12.5056 10.1074C12.5056 8.72667 11.3864 7.60742 10.0056 7.60742C8.62487 7.60742 7.50562 8.72667 7.50562 10.1074C7.50562 11.4882 8.62487 12.6074 10.0056 12.6074Z"
        stroke="#35434E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.67236 10.8412V9.37457C1.67236 8.5079 2.3807 7.79127 3.2557 7.79127C4.76403 7.79127 5.3807 6.7246 4.62236 5.41627C4.18903 4.66627 4.44736 3.69127 5.2057 3.25794L6.64736 2.43294C7.3057 2.04127 8.1557 2.27461 8.54736 2.93294L8.63903 3.09127C9.38903 4.39961 10.6224 4.39961 11.3807 3.09127L11.4724 2.93294C11.864 2.27461 12.714 2.04127 13.3724 2.43294L14.814 3.25794C15.5724 3.69127 15.8307 4.66627 15.3974 5.41627C14.639 6.7246 15.2557 7.79127 16.764 7.79127C17.6307 7.79127 18.3474 8.49957 18.3474 9.37457V10.8412C18.3474 11.7079 17.639 12.4246 16.764 12.4246C15.2557 12.4246 14.639 13.4912 15.3974 14.7996C15.8307 15.5579 15.5724 16.5246 14.814 16.9579L13.3724 17.7829C12.714 18.1746 11.864 17.9412 11.4724 17.2829L11.3807 17.1246C10.6307 15.8162 9.39736 15.8162 8.63903 17.1246L8.54736 17.2829C8.1557 17.9412 7.3057 18.1746 6.64736 17.7829L5.2057 16.9579C4.44736 16.5246 4.18903 15.5496 4.62236 14.7996C5.3807 13.4912 4.76403 12.4246 3.2557 12.4246C2.3807 12.4246 1.67236 11.7079 1.67236 10.8412Z"
        stroke="#35434E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
