export function SvgProfile2user() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.63338 9.16657C7.55005 9.15824 7.45005 9.15824 7.35838 9.16657C5.37505 9.0999 3.80005 7.4749 3.80005 5.4749C3.80005 3.43324 5.45005 1.7749 7.50005 1.7749C9.54175 1.7749 11.2001 3.43324 11.2001 5.4749C11.1917 7.4749 9.61675 9.0999 7.63338 9.16657Z"
        stroke="#35434E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.675 3.44189C15.2916 3.44189 16.5916 4.75023 16.5916 6.35856C16.5916 7.93356 15.3417 9.21689 13.7833 9.27523C13.7167 9.26689 13.6417 9.26689 13.5667 9.27523"
        stroke="#35434E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.4666 12.2419C1.44993 13.5919 1.44993 15.7919 3.4666 17.1336C5.75827 18.6669 9.51663 18.6669 11.8083 17.1336C13.825 15.7836 13.825 13.5836 11.8083 12.2419C9.52496 10.7169 5.7666 10.7169 3.4666 12.2419Z"
        stroke="#35434E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2834 16.7749C15.8834 16.6499 16.4501 16.4082 16.9168 16.0499C18.2168 15.0749 18.2168 13.4666 16.9168 12.4916C16.4584 12.1416 15.9001 11.9082 15.3084 11.7749"
        stroke="#35434E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
