import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import {
  SvgBarChart,
  SvgCategory,
  SvgFolderPlus,
  SvgLogo,
  SvgLogout,
  SvgProfile2user,
  SvgSetting2,
  SvgUserRectangle,
  SvgWallet,
} from '@partnerka-front/icons';
import { useUser } from '@partnerka-front/utils';

const NavWrapper = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  min-width: 280px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
`;

const NavLogo = styled.div`
  margin-bottom: 24px;
  padding: 24px;
  line-height: 1;
  font-size: 0;
`;

const NavItems = styled.ul`
  list-style: none;
`;

const NavItem = styled.li`
  width: 100%;
  margin-bottom: 10px;

  a {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.headliner};
    text-decoration: none;
    transition: 0.3s;

    & > span {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: 5px;
      border-radius: 0 2px 2px 0;
      background-color: ${({ theme }) => theme.colors.accent};
      opacity: 0;
      transition: 0.3s;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.basicLight};
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.basicLight};

      &:before {
        opacity: 1;
      }
    }
  }
`;

const NavLogout = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
`;

const NavFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  width: 100%;
  padding: 12px 30px;
  background-color: ${({ theme }) => theme.colors.inactiveGrey};
`;

const NavBtnSupport = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.support};
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.headliner};
  text-decoration: none;
  cursor: pointer;
`;

export function Nav() {
  const { t } = useTranslation(['common', 'deals']);
  const { logout, isAuth } = useUser();

  return (
    <NavWrapper>
      <div>
        <NavLogo>
          <Link to="/">
            <SvgLogo />
          </Link>
        </NavLogo>
        {isAuth && (
          <NavItems>
            <NavItem>
              <NavLink to="/">
                <span>
                  <SvgBarChart />
                  Статистика компании
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/finance">
                <span>
                  <SvgWallet />
                  Финансы
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/deals">
                <span>
                  <SvgFolderPlus />
                  Сделки
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/partners">
                <span>
                  <SvgProfile2user />
                  Мои партнёры
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/settings">
                <span>
                  <SvgUserRectangle />
                  Настройки компании
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/catalogs">
                <span>
                  <SvgCategory />
                  Каталоги
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/integration">
                <span>
                  <SvgSetting2 />
                  Настройка интеграции
                </span>
              </NavLink>
            </NavItem>
          </NavItems>
        )}
      </div>
      <div>
        {isAuth && (
          <NavLogout onClick={() => logout()}>
            <SvgLogout />
            {t('common.logout', { ns: 'common' })}
          </NavLogout>
        )}
        <NavFooter>
          <NavBtnSupport href={'https://t.me/cyberhackGL'} target={'_blank'}>
            Связаться с менеджером
          </NavBtnSupport>
        </NavFooter>
      </div>
    </NavWrapper>
  );
}
