import {
  CardAccountStatus,
  CardFinancialExpenditure,
  CardStatistics,
  GeneralStatisticsTable,
  Title,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Container } from '../components';
import { useStatistic } from '@partnerka-front/utils';
import { Api } from '@partnerka-front/api';
import { useEffect } from 'react';

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 10px;
`;

const CurrentStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export function CompanyStatisticsPage() {
  const { setStatistic, statistic } = useStatistic();

  const getStatistic = async () => {
    await Api.getBusinessStatistic().then((res) => setStatistic(res.data));
  };

  useEffect(() => {
    getStatistic();
  }, []);

  return (
    <>
      <Container>
        <Title>Статистика компании</Title>
        <Cards>
          {statistic && (
            <>
              <CardFinancialExpenditure />
              <CardAccountStatus />
              <CardStatistics />
            </>
          )}
        </Cards>
        <CurrentStatus>Актуальное состояние сделок (за все время)</CurrentStatus>
      </Container>
      <GeneralStatisticsTable
        title={'Общая статистика по сделкам'}
        description={
          'В этой таблице отображаются сделки по всем вашим партнёрам. Каждая сделка уникальна и не может находиться в нескольких статусах одновременно. Для уточнения результатов мы можете использовать фильтры и сортировки.'
        }
        isBusiness
      />
    </>
  );
}
