import { DateFormat, Price, TableItem, TableRow } from '@partnerka-front/components';
import { BusinessPartnerDto, Currency } from '@partnerka-front/types';

interface PartnersBusinessTableItemProps {
  partner: BusinessPartnerDto;
}

export function PartnersBusinessTableItem({ partner }: PartnersBusinessTableItemProps) {
  return (
    <TableRow gridTemplateColumns={'1fr 1fr 1fr 1fr'}>
      <TableItem noRightBorder minWidth={200}>
        {partner.uid}
      </TableItem>
      <TableItem noRightBorder minWidth={200}>
        <Price amount={partner.paid_balance} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder minWidth={200}>
        <Price amount={partner.hold_balance} currency={Currency.USD} />
      </TableItem>
      <TableItem noRightBorder minWidth={200}>
        <DateFormat date={partner.joined_at} dateFormat={'dd.MM.yyyy'} />
      </TableItem>
    </TableRow>
  );
}
