import { useForm, FormProvider } from 'react-hook-form';
import { CatalogDto, CatalogEvents, ResponseError, TypeMaterial } from '@partnerka-front/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  CardDropdown,
  CardItem,
  ControlledSelect,
  ControlledSimpleInput,
  Message,
  PageLoading,
} from '@partnerka-front/components';
import styled from 'styled-components';
import { Api } from '@partnerka-front/api';
import { CatalogSettingPromoMaterial } from './catalog-setting-promo-material';
import { useEventBus } from '@partnerka-front/utils';
import { toast } from 'react-toast';

const ItemMiddle = styled(CardItem)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const ItemLong = styled(CardItem)`
  grid-template-columns: 2fr 1fr;
  gap: 5px;
`;

const SectionSelect = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
`;

interface CatalogSettingPromoMaterialsProps {
  catalog: CatalogDto;
}

export function CatalogSettingPromoMaterials({ catalog }: CatalogSettingPromoMaterialsProps) {
  const { t } = useTranslation(['errors', 'validation']);
  const { emit } = useEventBus();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const options = {
    URL: 'URL',
    IMAGE: 'Изображение',
    FILE: 'Документ',
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required(`${t('validation.required', { ns: 'validation' })}`),
    url: Yup.string()
      .required(`${t('validation.required', { ns: 'validation' })}`)
      .url(`${t('validation.invalid_url', { ns: 'validation' })}`),
  });
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: '',
      type_material: 'URL',
      url: '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = formMethods;

  const handleTypeMaterial = (str: string) => {
    if (str === TypeMaterial.FILE) return TypeMaterial.FILE;
    if (str === TypeMaterial.IMAGE) return TypeMaterial.IMAGE;
    return TypeMaterial.URL;
  };

  const submit = handleSubmit(async (data) => {
    setPending(true);

    await Api.createPromoMaterial({
      catalog: catalog.id,
      title: data.title,
      type_material: handleTypeMaterial(data.type_material),
      url: data.url,
    })
      .then(() => {
        emit(CatalogEvents.fetchSettingCatalog);
        reset();
        toast.success('Промоматериал создан!');
      })
      .catch(setError);

    setPending(false);
  });

  return (
    <FormProvider {...formMethods}>
      {pending && <PageLoading />}

      <CardDropdown title={'Промоматериалы'}>
        {catalog.promo_materials.map((promoMaterial, index) => (
          <CatalogSettingPromoMaterial key={index} promoMaterial={promoMaterial} />
        ))}

        <ItemLong>
          <ControlledSimpleInput name={'title'} placeholder={'Заголовок'} />
        </ItemLong>

        <ItemLong>
          <SectionSelect>
            <ControlledSelect name={'type_material'} options={options} />
            <ControlledSimpleInput name={'url'} placeholder={`Вставьте ссылку`} />
          </SectionSelect>
        </ItemLong>

        <ItemMiddle>
          <Button variant={'basic'} maxWidth={'full'} onClick={submit} disabled={!isValid}>
            Добавить
          </Button>
        </ItemMiddle>

        {error?.response.data && (
          <Message onClose={() => setError(null)}>
            {error.response.data.detail
              ? t(`errors.${error.response.data.detail}`, { ns: 'errors' })
              : t('errors.try_again_later', { ns: 'errors' })}
          </Message>
        )}
      </CardDropdown>
    </FormProvider>
  );
}
