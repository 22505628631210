import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Back,
  Button,
  Message,
  PageLoading,
  Title,
  TitleSection,
} from '@partnerka-front/components';
import {
  CatalogSettingAwardPromo,
  CatalogSettingGeneralInformationUpdate,
  CatalogSettingLandings,
  CatalogSettingLocalization,
  CatalogSettingPromoMaterials,
  CatalogSettingRules,
  CatalogSettingOffers,
  CatalogSettingTraffic,
  Container,
} from '../components';
import { BusinessCatalogDto, GlobalEvents, InfoDto, ResponseError } from '@partnerka-front/types';
import { Api } from '@partnerka-front/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEventBus } from '@partnerka-front/utils';
import { toast } from 'react-toast';

const TitleButtons = styled.div`
  display: flex;
  gap: 12px;
`;

const DescriptionPage = styled.p`
  max-width: 800px;
  margin-top: 16px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Cards = styled.div`
  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export function CatalogSettingPage() {
  const { t } = useTranslation(['errors']);
  const { id } = useParams();
  const navigate = useNavigate();
  const { subscribe } = useEventBus();

  const [pending, setPending] = useState(false);
  const [catalog, setCatalog] = useState<BusinessCatalogDto | null>(null);
  const [tooltips, setTooltips] = useState<InfoDto[] | null>(null);
  const [errorPublic, setErrorPublic] = useState<ResponseError | null>(null);

  const getCatalog = async () => {
    setPending(true);

    if (id)
      await Api.getBusinessCatalog(id)
        .then((res) => setCatalog(res.data))
        .catch(() => navigate('/catalogs'));

    setPending(false);
  };
  subscribe(GlobalEvents.fetchSettingCatalog, getCatalog);

  const getTooltips = async () => {
    await Api.getInfo('catalogs_business')
      .then((res) => setTooltips(res.data))
      .catch(() => toast.error('Подсказки не загрузились!'));
  };

  const handlePublic = async () => {
    setPending(true);
    setErrorPublic(null);

    if (catalog)
      await Api.patchCatalog(catalog.id, { is_published: !catalog.is_published })
        .then(() => getCatalog())
        .catch(setErrorPublic);

    setPending(false);
  };

  useEffect(() => {
    getCatalog();
    getTooltips();
  }, []);

  if (!catalog) return <PageLoading />;

  return (
    <Container>
      {pending && <PageLoading />}
      <Back to={'/catalogs'} />
      <TitleSection marginBottom={16}>
        <Title marginBottom={0}>{catalog.title}</Title>
        <TitleButtons>
          <Button variant={'basic'} onClick={() => navigate(`/catalogs/preview/${catalog?.id}`)}>
            Предпросмотр
          </Button>
          {catalog.is_published ? (
            <Button variant={'inactiveGrey'} onClick={handlePublic}>
              Снять с публикации
            </Button>
          ) : (
            <Button variant={'accent'} onClick={handlePublic}>
              Опубликовать
            </Button>
          )}
        </TitleButtons>
      </TitleSection>

      {errorPublic?.response.data && (
        <Message onClose={() => setErrorPublic(null)}>
          {errorPublic.response.data.detail
            ? t(`errors.${errorPublic.response.data.detail}`, { ns: 'errors' })
            : t('errors.try_again_later', { ns: 'errors' })}
        </Message>
      )}

      <DescriptionPage>
        Любое изменения данных сделки логируется в системе. Это необходимо для защиты владельцев
        бизнеса, веб-мастеров и арбитражников в случае возникновления спортных моментов.
      </DescriptionPage>
      <Cards>
        <CatalogSettingGeneralInformationUpdate catalog={catalog} />
        <CatalogSettingAwardPromo catalog={catalog} tooltips={tooltips} />
        <CatalogSettingLocalization catalog={catalog} />
        <CatalogSettingLandings catalog={catalog} />
        <CatalogSettingOffers catalog={catalog} />
        <CatalogSettingTraffic catalog={catalog} />
        <CatalogSettingRules catalog={catalog} />
        <CatalogSettingPromoMaterials catalog={catalog} />
      </Cards>
    </Container>
  );
}
